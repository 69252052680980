@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
}

html {
  @apply h-full;
  scrollbar-gutter: stable;
}
body {
  @apply h-full;
}

#root {
  position: relative;
  min-height: 100vh;
}

.nav-dropdown {
  @apply text-center absolute flex flex-col w-min left-0 right-0 m-auto;
}

.btn-primary {
  @apply bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded;
}

.show-court-info {
  @apply py-1 px-2;
  white-space: nowrap;
}

.top-nav-item {
  @apply px-2 py-1 relative text-xs sm:text-sm lg:text-base font-bold text-white hover:text-blue-400;
}

#slider-nav {
  position: fixed;
}

.slider-nav-item {
  @apply px-2 py-1 relative text-base sm:text-lg lg:text-base font-bold text-white hover:text-blue-400;
}

.top-nav-item.active {
  @apply text-blue-400;
}

.slider-nav-item.active {
  @apply text-blue-400;
}

header {
  position: absolute;
  width: 100%;
  background: linear-gradient(to bottom, rgb(27, 27, 27), rgba(0, 0, 0, 0) 90%);
}

main {
  border: none;
}

footer {
  background: #23479a;
  color: white;
  position: absolute;
  bottom: 0;
}

section {
  border: none;
}

.main-section {
  display: flex;
  height: 100vh;
  background-position: top;
  background-size: cover;
  font-size: 24px;
}

.section-content-top {
  @apply h-96 flex flex-auto flex-col flex-wrap items-center bg-transparent gap-10 justify-center;
}

.section-content {
  @apply h-fit xl:h-96 flex flex-auto text-base md:text-xl lg:text-2xl flex-col flex-wrap items-center justify-center bg-white gap-10 p-10;
}

#mobile-navbar {
  display: none;
  right: 0;
}

#slider-nav {
  background: #23479a;
  width: 70%;
  right: -70%;
  transition: right .25s;
}

body:has(.slid) {
  overflow-y: hidden;
}

main {
  transition: right .25s;
  right: 0;
}

main.slid {
  right: 70%;
  overflow-y: hidden;
}

#slider-nav.active {
  right: 0;
}

@media screen and (max-width:800px) {
  #mobile-navbar {
    display: block;
    right: -70%;
  }

  #navbar {
    display: none;
  }
}

@media screen and (min-width:800px) {
  main {
    right: 0 !important;
  }
}

#signup-form-container {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-form {
  gap: 15px;
}

#signup-page label {
  font-weight: bold;
}

#signup-form-container input, #signup-form-container textarea {
  border: 1px solid black;
  padding: 3px 5px;
  border-radius: 4px;
}

#signup-page input, #signup-page select, #signup-page textarea {
  color: black;
}

#renter-signup-container {
  display: flex;
  gap: 10px;
  border-radius: 4px;
}

#renter-signup-form-container, #court-signup-form-container {
  background: black;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  padding: 20px;
  padding-bottom: 50px;
  color: white;
  border-radius: 8px;
}

#fairplay-container {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  padding: 20px;
  padding-bottom: 50px;
  border-radius: 8px;
}

#court-information {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 8px;
  background: black;
  color: white;
  padding: 20px;
}

main a {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

main a:hover {
  color: gray;
}

main a:visited {
  color: rgb(79, 4, 199);
}

main a:visited:hover {
  color: gray;
}

#available-courts-table {
  border: 1px solid white;
  border-collapse: collapse;
}

#available-courts-table th {
  border: 1px solid white;
  padding: 5px;
}

#available-courts-table td {
  border: 1px solid white;
  padding: 5px;
}

#home-page-video-container {
  height: 100vh;
  width: 100vw;
  position: absolute;
  max-width: 100%;
  z-index: -1;
}

#home-page-video {
  position: fixed;
  z-index: -1;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.section-content-top {
  height: 100vh;
}